<template>
  <v-dialog
    v-model="shown"
    width="700"
  >
    <v-card>
      <v-overlay v-if="importing">
        <v-progress-circular indeterminate size="80" color="primary"></v-progress-circular>
      </v-overlay>
      <v-card-title class="mb-5 primary white--text">
        <h2 class="my-5">Import Authors</h2>
      </v-card-title>

      <v-card-text>
        <v-form>
          <h3>Authors List</h3>
          <v-file-input v-model="authorsCsv" accept=".csv"></v-file-input>

          <h3>Book List</h3>
          <v-file-input v-model="booksCsv" accept=".csv"></v-file-input>
        </v-form>
        <p>Authors Added: {{ authorsAdded }}</p>
        <p>Authors Updated: {{ authorsUpdated }}</p>
      </v-card-text>

      <v-card-actions>
        <v-btn color="accent" large block @click="importFromCsvs">
          Import
          <v-icon right>mdi-import</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Papa from 'papaparse'
import { mapState, mapMutations } from 'vuex'
import { addDoc, updateDoc, doc, collection, query, where, getDocs } from 'firebase/firestore'

export default {
  data: () => ({
    shown: false,
    authorsCsv: null,
    booksCsv: null,
    importing: false,
    authorsAdded: 0,
    authorsUpdated: 0
  }),

  computed: {
    ...mapState(['firestore'])
  },

  mounted () {
    this.$root.$on('showAuthorImporter', () => {
      this.shown = true
    })
  },

  methods: {
    ...mapMutations(['setGlobalNotification']),
    /**
     * Checks to see if a target author already exists, by full name.
     * If the author exists, they're updated. Otherwise they're created in Firestore.
     */
    async addOrEditAuthorByFullName (authorData, writeData = true) {
      if (!writeData) console.warn('writeData is false. No data will be written to Firestore.')
      const querySnapshot = await getDocs(query(collection(this.firestore, 'authors'), where('fullName', '==', authorData.fullName)))
      // First, check to see if an author with this name exists
      if (querySnapshot.docs.length) {
        const authorId = querySnapshot.docs[0].id
        console.log(`EXISTING AUTHOR: Author ${authorData.fullName} present in database. Updating doc ${authorId}.`)
        if (writeData) await updateDoc(doc(this.firestore, 'authors', authorId), authorData)
        this.authorsUpdated++
      } else {
        console.log(`Creating new author ${authorData.fullName}.`)
        if (writeData) await addDoc(collection(this.firestore, 'authors'), authorData)
        this.authorsAdded++
      }
    },

    formatNames (rowData) {
      const lastNameColumn = 0
      const firstNameColumn = 1

      // Remove extra whitespace and newlines
      const firstNameFormatted = rowData[firstNameColumn]
        ? rowData[firstNameColumn].replace(/\r?\n|\r/g, '').replaceAll('*', '').trim()
        : 'FirstName'
      const lastNameFormatted = rowData[lastNameColumn]
        ? rowData[lastNameColumn].replace(/\r?\n|\r/g, '').replaceAll('*', '').trim()
        : 'LastName'
      // Used to check to see if the author already exists in Firestore
      const fullName = `${firstNameFormatted} ${lastNameFormatted}`
      return { firstNameFormatted, lastNameFormatted, fullName }
    },

    /**
     * Loops through the authors CSV, formats for Firestore, and creates / updates the target author.
     * Currently, choosing between adding or updating is determined entirely by the author's full name.
     */
    importAuthors () {
      return new Promise((resolve, reject) => {
        console.log('Importing authors...')

        // These numbers should be changed to match the column numbers in the CSV
        const emailsColumn = 8
        const booksColumn = 11

        let rowIndex = 0
        Papa.parse(this.authorsCsv, {
          step: async (row, parser) => {
            // Pause for async operations
            parser.pause()
            // Skip the header row
            if (rowIndex > 0) {
              const authorRow = row.data
              const { firstNameFormatted, lastNameFormatted, fullName } = this.formatNames(authorRow)
              const emailRow = authorRow[emailsColumn]
              let associatedEmails = []
              if (emailRow) {
                associatedEmails = emailRow.split('\n')
              }

              const booksData = authorRow[booksColumn]
              let booksSold = []
              if (booksData) {
                booksSold = booksData.split('\n')
              }
              // Format the payload for Firestore
              const authorData = {
                firstName: firstNameFormatted,
                lastName: lastNameFormatted,
                fullName,
                associatedEmails,
                booksSold
              }
              await this.addOrEditAuthorByFullName(authorData)
            }
            rowIndex++
            parser.resume()
          },
          complete: () => {
            console.log('Finished parsing authors CSV.')
            resolve()
          }
        })
      })
    },

    /**
     * Import book titles and associate them with the target author document,
     * or create a new author if they don't already exist.
     */
    importBooks () {
      return new Promise((resolve, reject) => {
        console.log('Importing books...')
        const booksColumn = 2

        let rowIndex = 0
        Papa.parse(this.booksCsv, {
          step: async (row, parser) => {
            parser.pause()
            // Skip header row
            if (rowIndex > 0) {
              const authorRow = row.data
              const { firstNameFormatted, lastNameFormatted, fullName } = this.formatNames(authorRow)
              const booksData = authorRow[booksColumn]

              let booksSold = []
              if (booksData) {
                booksSold = booksData.split('\n')
              }
              booksSold = booksSold.filter(book => book?.length > 0) // Remove empty cells
              const authorData = {
                firstName: firstNameFormatted,
                lastName: lastNameFormatted,
                fullName,
                booksSold
              }
              console.log('Author data:', authorData)
              await this.addOrEditAuthorByFullName(authorData)
            }
            rowIndex++
            parser.resume()
          },
          complete: () => {
            console.log('Finished parsing books CSV.')
            resolve()
          }
        })
      })
    },

    /**
     * Triggers the import of the authors or books CSV data, depending on availability.
     */
    async importFromCsvs () {
      this.importing = true
      // Reset import counts
      this.authorsAdded = 0
      this.authorsUpdated = 0

      if (this.authorsCsv) {
        console.log('Authors CSV present. Importing...')
        await this.importAuthors()
      }
      if (this.booksCsv) {
        console.log('Books CSV present. Importing...')
        await this.importBooks()
      }
      console.log('Finished all imports.')
      this.importing = false
      this.setGlobalNotification('CSV Import Complete')
    }
  },

  watch: {
    // Hide this modal if the route changes
    $route: function () {
      this.shown = false
    }
  }
}
</script>

<style>

</style>
